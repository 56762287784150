import PurchaseOperation from "@models/operations/PurchaseOperation";
import {plainToInstance} from "class-transformer";
import {computed, ComputedRef, ref, ToRef} from "vue";
import PaymentTimerService from "@/services/operations/payment/PaymentTimerService";
import LightPaymentPidResponseError from "@models/responses/light-payment-pid/LightPaymentPidResponseError";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import {FirebaseEvents} from "@/services/firebase/analytics/FirebaseEvents";
import FirebaseEventBuilder from "@/services/firebase/analytics/FirebaseEventBuilder";
import PaymentCheckImage from "@models/operations/payment/PaymentCheckImage";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import ServiceChat from "@/services/v2/data/service-chat/ServiceChat";
import ModelChatMessage from "@models/v2/chat/ModelChatMessage";
import RepositoryPurchase from "@/repositories/v2/repository-purchase/RepositoryPurchase";
import {f7} from "framework7-vue";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
// const {t} = useI18n({useScope: 'global'});
import PaymentServiceFeedbackCancel from "@/services/operations/payment/PaymentServiceFeedbackCancel";
import i18n from "@/langs/i18n";
import RouterService from "@/services/RouterService";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import {autobind} from "@/decorators/autobind";
import DocumentPurchase from "@/services/firebase/firestore/documents/DocumentPurchase";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import ErrorsService from "@/services/errors-service/ErrorsService";

export default class PaymentService {
    private _payment: ToRef<PurchaseOperation | null> = ref(null);
    private _timerService: PaymentTimerService = new PaymentTimerService();
    private _isExternalCreated: ToRef<boolean> = ref(false);
    private _paymentError: ToRef<LightPaymentPidResponseError | null> = ref(null);
    private readonly _repositoryPurchase: RepositoryPurchase = new RepositoryPurchase();
    private _feedbackCancelService: PaymentServiceFeedbackCancel = new PaymentServiceFeedbackCancel();
    private _isAmountWasChangedShowBlockWarning: ToRef<boolean> = ref(false);
    private _isAmountWasChangedModalVisible: ToRef<boolean> = ref(false);
    private _isAmountWasChangedModalWasShowed: ToRef<boolean> = ref(false);

    get feedbackCancelService(): PaymentServiceFeedbackCancel {
        return this._feedbackCancelService;
    }

    //TODO как-будто этот метод не должен находиться тут
    public async uploadCheckImages(checkImages: PaymentCheckImage[]) {
        await Promise.all(checkImages.map(async (checkImage: PaymentCheckImage) => {
            const messageModel: ModelChatMessage = await ServiceChat.of().sendTextMessage({
                msid: this._payment.value!.msid!,
                message: "",
                wait_files: 1
            });

            await ServiceChat.of().sendFileMessage({
                chatId: messageModel.chatId,
                messageId: messageModel.id,
                file: checkImage.file
            });
        }));
    }

    //TODO как-будто этот метод не должен находиться тут
    public async uploadPersonalData(message: string) {
        const payloadToSendMessage = {
            msid: this._payment.value?.msid,
            message: message
        };

        await ServiceMainApi.of().post<{ data: object }>('/api/mobile/client/chat', payloadToSendMessage);
    }

    get isAmountWasChanged(): ComputedRef<boolean> {
        return computed(() => {
            return this._payment.value?.amountUsd !== this._payment.value?.amountUsdOrigin;
        });
    }

    get isAmountWasChangedShowBlockWarning(): ToRef<boolean> {
        return this._isAmountWasChangedShowBlockWarning;
    }

    set isAmountWasChangedShowBlockWarning(value: ToRef<boolean>) {
        this._isAmountWasChangedShowBlockWarning = value;
    }

    get paymentError(): ToRef<LightPaymentPidResponseError | null> {
        return this._paymentError;
    }

    set paymentError(value: LightPaymentPidResponseError | null) {
        this._paymentError.value = value;
    }

    get isAmountWasChangedModalVisible(): ToRef<boolean> {
        return this._isAmountWasChangedModalVisible;
    }

    set isAmountWasChangedModalVisible(value: ToRef<boolean>) {
        this._isAmountWasChangedModalVisible = value;
    }

    get isAmountWasChangedModalWasShowed(): ToRef<boolean> {
        return this._isAmountWasChangedModalWasShowed;
    }

    set isAmountWasChangedModalWasShowed(value: ToRef<boolean>) {
        this._isAmountWasChangedModalWasShowed = value;
    }

    public paymentFactory(msid: string) {
        this._payment.value = plainToInstance(PurchaseOperation, new PurchaseOperation(), {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });
        this._payment.value.msid = msid;
    }

    public async createPayment(payload: createPaymentPayload) {
        const payment = await this._repositoryPurchase.createPurchase(payload);

        this._payment.value = payment;
        return payment;
    }

    public async recreatePayment() {
        this.checkCreatePayload();
        const payload: createPaymentPayload = {
            amount: this._payment.value!.amount!,
            // psystem_id: this._payment.value!.psystemObj?.id!,
            psystem_id: null,
            currency_id: this._payment.value!.currency?.id!,
            retry_for: this._payment.value!.msid!
        }
        await this.createPayment(payload);
    }

    // public async cancelOldPayments() {
    //     await ApiService.getInstance().patch('/api/main/client/payments/cancelOldCreated');
    // }

    //TODO скорее всего излишний, т.к есть fetchPayment
    public async updatePayment() {
        this.checkMSID();
        this._payment.value = await this._repositoryPurchase.fetchPurchase(this.payment.value!.msid!);
    }

    public async toAppealPayment() {
        this.checkMSID();

        this._payment.value = await this._repositoryPurchase.appealPurchase(this._payment.value!.msid!);
    }

    public async fetchPayment(payload: { msid: string }) {
        this._payment.value = await this._repositoryPurchase.fetchPurchase(payload.msid);
    }

    public async cancelPayment() {
        this.checkMSID();

        await this._repositoryPurchase.cancelPurchase(this._payment.value!.msid!);

        FirebaseService.of().analyticsEvent(
            FirebaseEvents.PAYMENT_CANCEL,
            FirebaseEventBuilder.of().build()
        );
    }

    public async confirmPayment() {
        this.checkMSID();

        this._payment.value = await this._repositoryPurchase.confirmPurchase(this._payment.value!.msid!);
    }

    public reset() {
        this._payment.value = null;
        this._timerService.stopIntervals();
        this._isAmountWasChangedShowBlockWarning.value = false;
        this._isAmountWasChangedModalVisible.value = false;
        this._isAmountWasChangedModalWasShowed.value = false;
    }

    public async checkPaymentTransactionStatus() {
        try {
            if (this._payment.value === null) return false;

            const purchaseOperation = await this._repositoryPurchase.fetchPurchase(this._payment.value!.msid!);

            if (purchaseOperation.requisites !== null && purchaseOperation.account !== null && purchaseOperation.status) {
                if (Object.values(BuyingOperationStatus).includes(purchaseOperation.status)) {
                    //TODO В будущем переделать на использование RouterService
                    f7.view.main.router.navigate('/payment');
                }
            }

            this._payment.value = purchaseOperation;
        } catch (e: any) {
            throw new Error(`Код ответа: ${e.code}\nСообщение: ${e.message}`);
        }
    }

    set payment(value: PurchaseOperation | null) {
        this._payment.value = value;
    }

    get payment(): ToRef<PurchaseOperation | null> {
        return this._payment;
    }

    get timerService(): PaymentTimerService {
        return this._timerService;
    }

    get isExternalCreated(): ToRef<boolean> {
        return this._isExternalCreated;
    }

    // set isExternalCreated(value: ToRef<boolean>) {
    //     this._isExternalCreated = value;
    // }

    private checkMSID() {
        if (this._payment.value === null) throw new Error(`[${this.constructor.name}]: payment is null`);
        if (this._payment.value.msid === null) throw new Error(`[${this.constructor.name}]: payment msid is null`);
    }

    private checkCreatePayload() {
        if (this._payment.value === null) throw new Error(`[${this.constructor.name}]: payment is null`);
        if (this._payment.value.msid === null) throw new Error(`[${this.constructor.name}]: payment msid is null`);
        // if (this._payment.value.psystemObj === null) throw new Error(`[${this.constructor.name}]: payment psystemObj is null`);
        // if (this._payment.value.psystemObj.id === null) throw new Error(`[${this.constructor.name}]: payment psystemObj.id is null`);
        if (this._payment.value.currency === null) throw new Error(`[${this.constructor.name}]: payment currency is null`);
        if (this._payment.value.currency.id === null) throw new Error(`[${this.constructor.name}]: payment currency.id is null`);
    }

    @autobind
    public async startCancelPaymentFlow() {
        try {
            this.showCancelPaymentDialog(
                async () => {
                    setTimeout(async () => {
                        f7.preloader.show();
                        let isShowFeedbackCancel = false;
                        await this._feedbackCancelService.fetchOptions();
                        const document = await this.fetchAndGetFirebasePaymentDocument();
                        if (document) {
                            if (document.isFeedbackCancelEnabled) {
                                isShowFeedbackCancel = true;
                            }
                        }
                        f7.preloader.hide();
                        if (isShowFeedbackCancel) {
                            this.feedbackCancelService.showModal();
                        }
                    })
                }
            );

        } catch (e) {
            await ErrorsService.of().handle(e);
        } finally {
            f7.preloader.hide();
        }
    }

    private async fetchAndGetFirebasePaymentDocument() {
        await FirebaseService.of().firestoreService?.fetchPurchaseDocument();
        return FirebaseService.of().firestoreService?.getDocument<DocumentPurchase>(DocumentTypes.PURCHASE);
    }

    @autobind
    private showCancelPaymentDialog(callback: Function | null = null) {
        // @ts-ignore
        const title = this.payment.value?.status === BuyingOperationStatus.ACCEPTED ?
            i18n.global.t("light-payment.status.accepted.alerts.cancel.title") :
            i18n.global.t("light-payment.status.created.alerts.cancel.title");
        const text = this.payment.value?.status === BuyingOperationStatus.ACCEPTED ?
            i18n.global.t("light-payment.status.accepted.alerts.cancel.text") :
            i18n.global.t("light-payment.status.created.alerts.cancel.text");
        f7.dialog.create({
            title: title,
            text: text,
            buttons: [
                {
                    text: i18n.global.t("findAgent.cancel.no"),
                },
                {
                    text: i18n.global.t("findAgent.cancel.yes"),
                    onClick: async () => {
                        try {
                            f7.preloader.show();
                            await this.cancelPayment();
                            if (callback) {
                                callback();
                            } else {
                                await AppController.of().service<RouterService>("router").toPayments();
                            }
                        } catch (e: any) {
                            f7.dialog.alert(i18n.global.t('g.errors.alert.default-text'), i18n.global.t('g.errors.alert.default-title'));
                        } finally {
                            f7.preloader.hide();
                        }
                    }
                }
            ]
        }).open();
    }

    public showAmountWasChangedModal() {
        this.isAmountWasChangedModalWasShowed.value = true;
        this._isAmountWasChangedModalVisible.value = true;
        setTimeout(() => {
            this.showAmountWasChangedModalBackdrop();
            this.showAmountWasChangedModalContent();
        }, 0);
    }

    public hideAmountWasChangedModal() {
        this.removeAmountWasChangedModalBackdrop();
        this.hideAmountWasChangedModalContent();
        setTimeout(() => {
            this._isAmountWasChangedModalVisible.value = false;
        }, 314);
    }

    private removeAmountWasChangedModalBackdrop() {
        const el: HTMLElement | null = document.querySelector(".payment-amount-changed-modal-backdrop");
        if (el) {
            el.style.opacity = "0";
            setTimeout(() => {
                el.remove();
            }, 314);
        }
    }

    private hideAmountWasChangedModalContent() {
        const el: HTMLElement | null = document.querySelector(".payment-amount-changed-modal");
        if (el != null) {
            el.style.opacity = "0";
            el.classList.remove("hide");
            setTimeout(() => {
                el.style.display = "none";
            }, 314)
        }
    }

    private showAmountWasChangedModalBackdrop() {
        const el = document.createElement("div");
        el.classList.add("payment-amount-changed-modal-backdrop");
        // el.addEventListener("click", () => {
        //     this.hideModal();
        // });
        const parent = document.getElementById("framework7-root");
        if (parent) parent.appendChild(el);
        setTimeout(() => {
            el.style.opacity = "1";
        }, 50);
    }

    private showAmountWasChangedModalContent() {
        const el: HTMLElement | null = document.querySelector(".payment-amount-changed-modal");
        if (el != null) {
            el.style.display = "block";
            setTimeout(() => {
                el.style.opacity = "1";
                el.classList.remove("hide");
            }, 50)
        }
    }
}

export type createPaymentPayload = {
    amount: number,
    psystem_id?: number | null,
    account_ids?: number[],
    currency_id?: number,
    retry_for?: string,
    msid?: string
}

export type fetchPaymentPayload = {
    msid: string
}
