import humps from "lodash-humps-ts";
import {f7} from "framework7-vue";
import {nextTick} from "vue";
import Animations from "@/helpers/animations";
import SocketService from "@/services/SockerService";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import PaymentService from "@/services/operations/payment/PaymentService";
import WithdrawalService from "@/services/operations/withdrawal/WithdrawalService";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import ServiceChat from "@/services/v2/data/service-chat/ServiceChat";
import ModelChatMessage from "@models/v2/chat/ModelChatMessage";
import ModelChatSocket from "@models/v2/chat/ModelChatSocket";
import ModelChatF7Socket from "@models/v2/chat/f7/ModelChatF7Socket";
import ServiceOperations from "@/services/v2/data/service-operations/ServiceOperations";

interface IChatMessageRead {
    chat: {
        id: number;
        paymentId: number;
        theme: "sale" | "purchase";
    },
    messageId: number;
}

export default class ChatDataController {
    private static _instance?: ChatDataController;

    public static getInstance() {
        if (typeof this._instance === "undefined") this._instance = new ChatDataController();
        return this._instance;
    }

    public socketConnect() {
        try {
            SocketService.getInstance().gate
                .listen('.NewChatMessage', async (data: object) => {
                    const modelChatSocket = ServiceChat.of().modelChatSocketFactory(data);
                    const modelChatF7Socket = ServiceChat.of().modelChatF7SocketFactory(modelChatSocket)

                    if (modelChatF7Socket.message.senderType === "system") return;
                    let {url} = f7.view.current.router.currentRoute;

                    url = url.split('?')[0];
                    switch (url) {
                        case "/chat-withdrawal":
                            if (!this.isCheckCurrentWithdrawal(modelChatF7Socket.chat.paymentId)) break;
                            this.produceMessages(modelChatF7Socket);
                            await this.markMessageAsRead(modelChatF7Socket);
                            break;
                        case "/chat":
                            if (!this.isCheckCurrentPayment(modelChatF7Socket.chat.paymentId)) break;
                            this.produceMessages(modelChatF7Socket);
                            await this.markMessageAsRead(modelChatF7Socket);
                            break;
                        case "/payments":
                            this.incrementPaymentChatCount(modelChatF7Socket);
                            break;
                        case "/withdrawal":
                            if (!this.isCheckCurrentWithdrawal(modelChatF7Socket.chat.paymentId)) break;
                            this.incrementPaymentChatCount(modelChatF7Socket);
                            break;
                        case "/payment":
                            if (!this.isCheckCurrentPayment(modelChatF7Socket.chat.paymentId)) break;
                            this.incrementPaymentChatCount(modelChatF7Socket);
                            break;
                        default:
                    }
                })
                .listen('.ChatMessagesRead', async (data: object) => {
                    const modelChatSocket: ModelChatSocket = ServiceChat.of().modelChatSocketFactory(data);

                    const {url} = f7.view.current.router.currentRoute;
                    switch (url) {
                        case "/payments":
                            this.resetPaymentChatCount(modelChatSocket.chat.paymentId);
                            break;
                        case "/chat-withdrawal":
                            ServiceChat.of().readMessages();
                            break;
                        case "/chat":
                            ServiceChat.of().readMessages();
                            break;
                        case "/withdrawal":
                            break;
                        case "/payment":

                            break;
                        default:
                    }
                })
                .listen('.ChatMessageRead', (data: object) => {
                    const humpsData: IChatMessageRead = humps(data);
                    ServiceChat.of().readMessage(humpsData.messageId);
                });
        } catch (e) {
            console.info('socketConnect error')
        }
    }

    private isCheckCurrentPayment(messagePaymentId: number) {
        const paymentService: PaymentService = AppController.getInstance().paymentService;
        let {payment} = paymentService;
        return !!(payment.value && payment.value.id === messagePaymentId);
    }

    private isCheckCurrentWithdrawal(messagePaymentId: number) {
        const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;
        let {withdrawal} = withdrawalService;
        return !!(withdrawal.value && withdrawal.value.id === messagePaymentId);
    }

    public incrementPaymentChatCount(data: ModelChatF7Socket) {
        let {url} = f7.view.current.router.currentRoute;
        url = url.split('?')[0];
        switch (url) {
            case "/withdrawal":
                const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;
                const localWithdrawal: WithdrawalOperation | null = withdrawalService.withdrawal.value;
                localWithdrawal?.incrementChatUnreadCount();

                nextTick(() => {
                    const el = document.querySelector(`.withdrawal-view__subnavbar .subnavbar-chat__mark`) as HTMLElement;
                    Animations.shakeAnimation(el);
                });
                break;
            case "/payment":
                const paymentService: PaymentService = AppController.getInstance().paymentService;
                const localPayment: PurchaseOperation | null = paymentService.payment.value;
                localPayment?.incrementChatUnreadCount();

                nextTick(() => {
                    const el = document.querySelector(`.payment-view__subnavbar .subnavbar-chat__mark`) as HTMLElement;
                    Animations.shakeAnimation(el);
                });
                break;
            // case "/payment/light":
            //     const appController = AppController.getInstance();
            //     (appController.lightBuyingService as LightBuyingService).buying.value?.incrementChatUnreadCount();
            //
            //     nextTick(() => {
            //         const el = document.querySelector(`.light-payment__navbar .subnavbar-chat__mark`) as HTMLElement;
            //         Animations.shakeAnimation(el);
            //     });
            //     break;
            default:
        }

        const isIncremented = ServiceOperations.of().incrementChatUnreadCountByOperationId(data.chat.paymentId);

        if (isIncremented) {
            nextTick(() => {
                const el = document.querySelector(`.payments-view .my-payments .payment-${data.chat.paymentId} .alert-message-svg`) as HTMLElement;
                Animations.shakeAnimation(el);
            });
        }
    }

    public resetPaymentChatCount(paymentId: number) {
        let {url} = f7.view.current.router.currentRoute;
        url = url.split('?')[0];
        switch (url) {
            case "/chat-withdrawal":
            case "/withdrawal":
                const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;
                const localWithdrawal: WithdrawalOperation | null = withdrawalService.withdrawal.value;
                localWithdrawal?.resetChatUnreadCount();
                break;
            case "/chat":
            case "/payment":
                const paymentService: PaymentService = AppController.getInstance().paymentService;
                const localPayment: PurchaseOperation | null = paymentService.payment.value;
                localPayment?.resetChatUnreadCount();
                break;
            default:
        }

        ServiceOperations.of().resetChatUnreadCountByOperationId(paymentId);
    }

    public async markMessageAsRead(payload: ModelChatF7Socket) {
        await ServiceChat.of().markMessageAsRead({
            chatId: payload.chat.id,
            messageId: payload.message.id
        });
    }

    public produceMessages(payload: ModelChatF7Socket) {
        ServiceChat.of().addMessage(payload.message)
    }

    public appendMessage(message: ModelChatMessage) {
        const model = ServiceChat.of().modelChatF7MessageFactory(message);
        ServiceChat.of().addMessage(model);
    }
}
