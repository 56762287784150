import {Ref, ref, ToRef} from "vue";
import {autobind} from "@/decorators/autobind";
import ViewController from "@/interfaces/ViewController";
// @ts-ignore
import AppController from '@target/components/App/ts/AppController';
import PaymentService, {createPaymentPayload} from "@/services/operations/payment/PaymentService";
import PurchaseRepository from "@/repositories/purchase/PurchaseRepository";
import AgentsAmountRanges from "@models/errors/components/AgentsAmountRanges";
import LightPaymentPidResponseError from "@models/responses/light-payment-pid/LightPaymentPidResponseError";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ModelAccount from "@models/v2/account/ModelAccount";
import DocumentPurchase from "@/services/firebase/firestore/documents/DocumentPurchase";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";

export default class NewPaymentController implements ViewController {
    private static instance?: NewPaymentController;
    private _purchaseRepository: PurchaseRepository = new PurchaseRepository();
    private _paymentService: PaymentService;
    private _minAmountEnabled: ToRef<boolean> = ref(false);
    private _minAmount: ToRef<number | null> = ref(null);
    private MIN_DEFAULT_AMOUNT_DOLLARS = 15;
    private _account = ServiceAccount.of().account as Ref<ModelAccount>;
    private readonly _currency: Ref<any>;
    public agentsAmountRanges: Ref<AgentsAmountRanges | null> = ref(null);
    public isPaymentSystemsLoading: Ref<Boolean> = ref(false);
    public inputCurrency: Ref<string>;
    public amount: Ref<string>;
    public btnContinueLoading: Ref<boolean>;

    static getInstance() {
        if (typeof this.instance === 'undefined') {
            this.instance = new NewPaymentController();
        }
        return this.instance;
    }

    private constructor() {
        const payment: PurchaseOperation = AppController.getInstance().paymentService.payment.value;

        AppController.getInstance().setPaymentRequestData(null);
        this._paymentService = AppController.getInstance().paymentService;
        this._currency = ref(ServiceAccount.of().account.value?.currency);
        this.inputCurrency = ref(this._currency.value.name);

        this.amount = ref(`${payment.amount} ${this._currency.value.abbr}`);
        this.btnContinueLoading = ref(false);

        this.setPurchaseFirebaseConfig();
        this.updateAgentsAmountRanges();
    }

    private setPurchaseFirebaseConfig() {
        const doc = FirebaseService.of().firestoreService?.getDocument<DocumentPurchase>(DocumentTypes.PURCHASE);
        if (doc) {
            if (doc.minAmountEnabled) {
                this._minAmountEnabled.value = doc.minAmountEnabled;
            }

            if (doc.minAmount !== null) {
                this._minAmount.value = doc.minAmount;
            }
        }
    }

    public updateAgentsAmountRanges() {
        const paymentError: ToRef<LightPaymentPidResponseError> = AppController.getInstance().paymentService.paymentError;

        if (paymentError.value.data && paymentError.value.data.ranges) {
            if (this._minAmountEnabled.value) {
                this.agentsAmountRanges.value = this.calculateMinMaxAmountRangesByFirebase(paymentError.value.data.ranges);
            } else {
                this.agentsAmountRanges.value = paymentError.value.data.ranges;
            }
        }
    }

    private calculateMinMaxAmountRangesByFirebase(agentsAmountRanges: AgentsAmountRanges) {
        const { buy } = this._account.value!.currency.rates;
        let minAmount = (this._minAmount.value ?? this.MIN_DEFAULT_AMOUNT_DOLLARS) * buy;

        if (minAmount > agentsAmountRanges.min!) {
            agentsAmountRanges.min = Math.min(minAmount, agentsAmountRanges.max!);
        }

        return agentsAmountRanges;
    }

    public reset() {
        this.agentsAmountRanges.value = null;
    }

    get currency(): Ref<any> {
        return this._currency;
    }

    public validateMinmax() {
        let amount = parseFloat(this.amount.value.replace(/\s/g, ''));

        if (!this.agentsAmountRanges.value) return true;

        const {min, max} = this.agentsAmountRanges.value;
        if (min == null || max == null) return true;
        if (min == 0 || max == 0) return true;

        return !(amount < min || amount > max);
    }

    @autobind
    async next() {
        let amount = parseFloat(this.amount.value.replace(/\s/g, ''));
        const payload: createPaymentPayload = {
            amount: amount,
            msid: this._paymentService.payment.value!.msid!
        }
        const operation = await this._purchaseRepository.patchEmptyPayment(payload);
        FirebaseService.of().wrongRangeMetricSuccess.checkAndSendAnalyticEvent();
        FirebaseService.of().emptyRangeMetricSuccess.checkAndSendAnalyticEvent();
        return operation;
    }

    destructor() {
        this.reset();
        NewPaymentController.instance = undefined;
    }
}
