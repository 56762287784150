import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import { CreatePaymentPayload } from "./types";

export default class RepositoryPurchase {
    public async fetchPurchase(msid: string): Promise<PurchaseOperation> {
        const response = await ServiceMainApi.of().get<{ data: object }>(`/api/mobile/client/payments/get/${msid}`);

        return this.plainToModel(response.data);
    }

    public async createPurchase(payload: CreatePaymentPayload): Promise<PurchaseOperation> {
        //TODO тут надо подумать на счет createPaymentPayload возможно нужна DTO
        const response = await ServiceMainApi.of().post<{ data: any }>('/api/mobile/client/payments/create', payload);

        return this.plainToModel(response.data);
    }

    public async confirmPurchase(msid: string): Promise<PurchaseOperation> {
        const response = await ServiceMainApi.of().post<{ data: object }>('/api/mobile/client/payments/confirm', { msid });

        return this.plainToModel(response.data);
    }

    public async cancelPurchase(msid: string): Promise<{ data: string, success: boolean }> {
        return await ServiceMainApi.of().patch<{ data: string, success: boolean }>('/api/mobile/client/payments/cancel', { msid });
    }

    public async appealPurchase(msid: string): Promise<PurchaseOperation> {
        const response = await ServiceMainApi.of().patch<{ data: object }>('/api/mobile/client/payments/appeal', { msid });

        return this.plainToModel(response.data);
    }

    private async plainToModel(data: object): Promise<PurchaseOperation> {
        const operation: PurchaseOperation = plainToInstance(PurchaseOperation, data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        await validateOrReject(operation);

        return operation;
    }
}
