<template>
    <div class="payment-view__content">
        <template v-if="payment">
            <transition name="fade" mode="out-in">
                <template v-if="paymentError">
                    <PaymentError :payment="payment"/>
                </template>
                <template v-else>
                    <transition name="fade" mode="out-in">
                        <template v-if="payment.status === BuyingOperationStatus.CREATED">
                            <PaymentStatusCreated
                                @cancelPayment="cancelFindAgent"
                                :cancel="true"
                            >
                                <template #timer>
                                    <PaymentStatusTimer
                                        :timeText="timeText"
                                        :percent="percent"
                                        :size="100"
                                        :borderWidth="8"
                                        :hiddenTimer="false"
                                        :fontSize="28"
                                        class="created"
                                    />
                                </template>
                            </PaymentStatusCreated>
                        </template>
                        <template v-else-if="payment.status === BuyingOperationStatus.ACCEPTED">
                            <div class="wrapper-elements">
                                <PaymentStatusAccepted
                                    :payment="payment"
                                    :timeText="timeText"
                                    :percent="percent"
                                    @cancelPayment="cancelFindAgent"
                                />
                            </div>
                        </template>
                        <template v-else-if="payment.status === BuyingOperationStatus.CASHED">
                            <PaymentStatusCashed :payment="payment" @uploadCheckImages="uploadCheckImages"
                                                 @uploadPersonalData="uploadPersonalData">
                                <template #timer>
                                    <PaymentStatusTimer
                                        :timeText="timeText"
                                        :percent="percent"
                                        :size="100"
                                        :hidden-timer="false"
                                        :borderWidth="8"
                                        :fontSize="28"
                                        class="cached"
                                    />
                                </template>
                            </PaymentStatusCashed>
                        </template>
                        <template v-else-if="payment.status === BuyingOperationStatus.EXPIRED">
                            <PaymentStatusExpired
                                :payment="payment"
                                :timeText="timeText"
                                :percent="percent"
                                @submit="recreatePayment"
                            >
                                <template #timer>
                                    <PaymentStatusTimer
                                        :timeText="timeText"
                                        :percent="percent"
                                        :hiddenTimer="false"
                                        :size="100"
                                        :borderWidth="8"
                                        :fontSize="28"
                                        class="expired"
                                    />
                                </template>
                            </PaymentStatusExpired>
                        </template>
                        <template v-else-if="payment.status === BuyingOperationStatus.CONFIRMED">
                            <PaymentStatusConfirmed :payment="payment"/>
                        </template>
                        <template v-else-if="payment.status === BuyingOperationStatus.CANCELED">
                            <PaymentStatusCanceled :payment="payment"/>
                        </template>
                        <template v-else-if="payment.status === BuyingOperationStatus.ONAPPEAL">
                            <PaymentStatusOnappeal :payment="payment" @toChat="toChat"/>
                        </template>
                        <template v-else-if="payment.status === BuyingOperationStatus.REJECTED">
                            <PaymentStatusRejected :payment="payment"/>
                        </template>
                        <template v-else-if="payment.status === BuyingOperationStatus.EMPTY">
                            <PaymentStatusEmpty :payment="payment"/>
                        </template>
                    </transition>
                </template>
            </transition>
        </template>
    </div>
</template>

<script setup lang="ts">
import {onMounted, PropType, toRef, UnwrapRef, watch} from "vue";
import PaymentStatusCreated from "./contents/PaymentStatusCreated.vue";
import PaymentStatusTimer from "./atomic/PaymentStatusTimer.vue";
import PaymentStatusCanceled from "./contents/PaymentStatusCanceled.vue";
import PaymentStatusAccepted from "./contents/PaymentStatusAccepted.vue";
import PaymentStatusConfirmed from "./contents/PaymentStatusConfirmed.vue";
import PaymentStatusRejected from "./contents/PaymentStatusRejected.vue";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import PaymentTimerService from "@/services/operations/payment/PaymentTimerService";
import PaymentStatusCashed from "@/views/payment/components/contents/PaymentStatusCashed.vue";
import PaymentStatusOnappeal from "@/views/payment/components/contents/PaymentStatusOnappeal.vue";
import PaymentStatusExpired from "../components/contents/PaymentStatusExpired.vue";
import PaymentStatusEmpty from "@/targets/light/views/payment/components/contents/PaymentStatusEmpty.vue";
import PaymentError from "@/targets/light/views/payment/components/contents/error/PaymentError.vue";
import LightPaymentPidResponseError from "@models/responses/light-payment-pid/LightPaymentPidResponseError";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import PaymentCheckImage from "@models/operations/payment/PaymentCheckImage";

const props = defineProps({
    payment: {
        type: Object as PropType<PurchaseOperation>,
        required: true
    },
    timerService: {
        type: Object as PropType<PaymentTimerService>,
        required: true
    }
});

const payment = toRef(props, 'payment');
const paymentError: LightPaymentPidResponseError = AppController.getInstance().paymentService.paymentError;
const timerService: PaymentTimerService = props.timerService;
const timeText = timerService.getTimeText;
const percent = timerService.getPercent;
const emits = defineEmits(['timerExpired', 'cancelPayment', 'toChat', 'recreatePayment', 'uploadCheckImages', "uploadPersonalData"]);

function uploadPersonalData(message: string, callback: Function, failedCallback: Function) {
    emits("uploadPersonalData", message, callback, failedCallback)
}

const recreatePayment = (callback: Function) => {
    emits('recreatePayment', callback);
}

function uploadCheckImages(checkImages: PaymentCheckImage[], callback: Function, failedCallback: Function) {
    emits("uploadCheckImages", checkImages, callback, failedCallback)
}

function cancelFindAgent() {
    emits('cancelPayment');
}

const toChat = () => {
    emits('toChat');
}

onMounted(async () => {
    initTimerService();
});

watch(() => payment.value!.status, () => {
    initTimerService();
});

watch(() => percent.value < 0, (statement) => {
    if(statement)
    emits('timerExpired');
});

const initTimerService = () => {
    if (payment?.value?.status &&
        [
            BuyingOperationStatus.EMPTY,
            BuyingOperationStatus.ACCEPTED,
            BuyingOperationStatus.CREATED,
            BuyingOperationStatus.CASHED,
        ].includes(payment.value.status)) {
        timerService.start(payment.value.status, payment.value.updatedAt!);
    } else {
        timerService.stopIntervals();
    }
}
</script>

<style lang="scss">
.payment-view__content {
    position: relative;

    .wrapper-elements {
        padding: 0 16px;
    }

    .cancel {
        margin-bottom: 16px;
    }
}
</style>
