<template>
    <f7-page
        class="payment-view"
        ref="self"
        name="payment"
        :page-content="false"
        @page:beforeout="pageBeforeOutEvent"
        @page:beforein="pageBeforeInEvent"
    >
        <PaymentViewNavbar
            ref="navbar"
            :timer-service="paymentService.timerService"
            :payment="payment"
            @closeView="toPayments"
            @openPaymentDetailsPopup="openPaymentDetailsPopup"
        />
        <PaymentViewSubnavbar
            ref="subnavbar"
            :payment="payment"
            :with-transition="withTransitionSubnavbar"
            @toChat="toChat"
        />
        <f7-page-content :class="{'with-transition-subnavbar': withTransitionSubnavbar}" ptr @ptr:refresh="refreshPage">
            <transition name="fade" mode="out-in">
                <template v-if="payment && !pageLoading">
                    <PaymentViewContent
                        :payment="payment"
                        :timer-service="paymentService.timerService"
                        @cancelPayment="startCancelPaymentFlow"
                        @toChat="toChat"
                        @recreatePayment="recreatePayment"
                        @upload-check-images="uploadCheckImages"
                        @uploadPersonalData="uploadPersonalData"
                    />
                </template>
                <template v-else>
                    <PaymentViewLoader/>
                </template>
            </transition>
        </f7-page-content>
        <template v-if="payment && !pageLoading">
            <PaymentViewBottomButtons
                :payment="payment"
                @closeView="toPayments"
                @confirmPayment="confirmPayment"
            />
            <PaymentViewDetailsPopup ref="paymentViewDetailsPopup"/>
        </template>
    </f7-page>
</template>

<script lang="ts" setup>
//@ts-ignore
import AppController from "@target/components/App/ts/AppController";
import {onBeforeMount, onMounted, onUnmounted, ref, Ref, watch} from "vue";
import PaymentService from "@/services/operations/payment/PaymentService";
import PaymentViewLoader from "./components/atomic/PaymentViewLoader.vue";
import PaymentViewContent from "./components/PaymentViewContent.vue";
import PaymentViewNavbar from "./components/PaymentViewNavbar.vue";
import PaymentViewBottomButtons from "@/views/payment/components/atomic/PaymentViewBottomButtons.vue";
import {f7} from "framework7-vue";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import {useI18n} from "vue-i18n";
import PaymentViewDetailsPopup from "@/views/payment/components/PaymentViewDetailsPopup.vue";
import PaymentViewSubnavbar from "@/views/payment/components/atomic/PaymentViewSubnavbar.vue";
import {ToRef} from "@vue/runtime-core";
import HuntingAgentsService from "@/services/others/HuntingAgentsService";
import {getDevice} from "framework7/shared/get-device";
import EmptyPaymentHelper from "@/targets/light/views/payment/ts/EmptyPaymentHelper";
import LogService from "@/services/log-service/LogService";
import PaymentCheckImage from "@models/operations/payment/PaymentCheckImage";
import ErrorsService from "@/services/errors-service/ErrorsService";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";

const self: any = ref(null);
const navbar: any = ref(null);
const subnavbar: any = ref(null);
const withTransitionSubnavbar: ToRef<boolean> = ref(false);
const paymentService: PaymentService = AppController.of().paymentService;
let { payment } = paymentService;
const pageLoading = ref(false);
const account = ServiceAccount.of().account;
const {t} = useI18n({useScope: 'global'});
const paymentViewDetailsPopup: Ref<any> = ref(null);

async function uploadCheckImages(checkImages: PaymentCheckImage[], callback: Function, failedCallback: Function) {
    try {
        f7.preloader.show();
        await paymentService.uploadCheckImages(checkImages);
        callback();
    } catch (e: any) {
        failedCallback();
        ErrorsService.of().handle(e);
    } finally {
        f7.preloader.hide();
    }
}

async function uploadPersonalData(message: string, callback: Function, failedCallback: Function) {
    try {
        f7.preloader.show();
        await paymentService.uploadPersonalData(message);
        callback();
    } catch (e: any) {
        failedCallback();
        await ErrorsService.of().handle(e);
    } finally {
        f7.preloader.hide();
    }
}

const refreshPage = async (done: Function) => {
    try {
        await paymentService.updatePayment();
    } catch (e: any) {
        LogService.of().log("RefreshEvent", e.message);
        f7.dialog.alert(t('g.errors.alert.default-text'), t('g.errors.alert.default-title'));
    } finally {
        setTimeout(async () => {
            done();
        }, 1000);
    }
}
const toPayments = () => {
    pushEvent("close");
    setTimeout(() => {
        if (payment.value!.status === BuyingOperationStatus.CONFIRMED && payment.value!.successUrl) document.location.href = payment.value!.successUrl;
        else if (payment.value!.status === BuyingOperationStatus.REJECTED && payment.value!.failUrl) document.location.href = payment.value!.failUrl;
        else if (payment.value!.status === BuyingOperationStatus.CANCELED && payment.value!.failUrl) document.location.href = payment.value!.failUrl;
    }, 314);
}
const pushEvent = (eventName: string) => {
    try {
        window.parent.postMessage({message: "close"}, "*");
        if (getDevice().ios) {
            // @ts-ignore
            window.webkit.messageHandlers.iosListener.postMessage("close");
        } else if (getDevice().android) {
            // @ts-ignore
            window.webInterface.onMessage("close");
        }
    } catch (e) {
        console.error(e);
    }
}
const toChat = () => {
    // @ts-ignore
    f7.views.higher.router.navigate('/chat', {
        openIn: 'popup'
    });
}
const confirmPayment = async (callback: Function) => {
    try {
        await paymentService.confirmPayment();
    } catch (e: any) {
        f7.dialog.alert(t('g.errors.alert.default-text'), t('g.errors.alert.default-title'));
    } finally {
        callback();
    }
}
const startCancelPaymentFlow = async () => {
    await paymentService.startCancelPaymentFlow();
}
const openPaymentDetailsPopup = () => {
    paymentViewDetailsPopup.value!.payment = payment.value;
    setTimeout(() => {
        paymentViewDetailsPopup.value.detectHeight()
        paymentViewDetailsPopup.value!.isOpened = true;
    }, 0);
}
const showSubnavbar = () => {
    const navbarDOM = (navbar.value.$el) as HTMLElement;
    navbarDOM.classList.add('with-custom-subnavbar');
    const pageContentDOM = (self.value.$el as HTMLElement).querySelector('.page-content') as HTMLElement;
    pageContentDOM.style.setProperty('--f7-page-content-extra-padding-top', '44px');// paddingTop = `calc(var(--f7-safe-area-top) + 88px)`;
    subnavbar.value.showSubnavbar = true;
}
const hideSubnavbar = () => {
    const navbarDOM = (navbar.value.$el) as HTMLElement;
    navbarDOM.classList.remove('with-custom-subnavbar');
    const pageContentDOM = (self.value.$el as HTMLElement).querySelector('.page-content') as HTMLElement;
    pageContentDOM.style.setProperty('--f7-page-content-extra-padding-top', '0px');
    subnavbar.value.showSubnavbar = false;
}
const externalPaymentFlowInit = async () => {
    try {
        pageLoading.value = true;
        // await paymentService.cancelOldPayments();
        await paymentService.updatePayment();
    } catch (e: any) {
        console.log(e)
        if (e.code !== 401) {
            f7.dialog.alert(t('g.errors.alert.default-text'), t('g.errors.alert.default-title'));
        }
    } finally {
        pageLoading.value = false;
    }
}

function checkAndShowIsAmountWasChangedModal() {
    if (
        payment.value?.status === BuyingOperationStatus.ACCEPTED &&
        paymentService.isAmountWasChanged.value
    ) {
        if (
            !paymentService.isAmountWasChangedModalVisible.value &&
            !paymentService.isAmountWasChangedModalWasShowed.value
        ) {
            paymentService.showAmountWasChangedModal();
        }
    }
}

watch(payment, (value, oldValue) => {
    if (value) {
        HuntingAgentsService.of().checkShowToast(value);
        if (value.status === BuyingOperationStatus.EMPTY) {
            if (oldValue == null) EmptyPaymentHelper.of().handle(value);
        }
    }
    checkAndShowIsAmountWasChangedModal();
    checkAndRunTimerCallbackFunc();
    if (checkShowSubnavbar()) {
        withTransitionSubnavbar.value = true;
        setTimeout(() => {
            showSubnavbar();
        }, 0)
    } else hideSubnavbar();
    checkAndChangePagePaddingBottom();
});
watch(account, async (newValue, oldValue) => {
    if (typeof oldValue !== "undefined") return;
    if (paymentService.isExternalCreated.value) {
        await externalPaymentFlowInit();
        paymentService.isExternalCreated.value = false;
    }
});

function pageBeforeOutEvent() {
    HuntingAgentsService.of().hideToast();
}

function pageBeforeInEvent() {
    if (payment.value) HuntingAgentsService.of().checkShowToast(payment.value);
}

const checkShowSubnavbar = () => {
    let status = false;
    if (payment.value &&
        payment.value?.status && [
            BuyingOperationStatus.CREATED,
            BuyingOperationStatus.CASHED,
            BuyingOperationStatus.REJECTED,
            BuyingOperationStatus.ACCEPTED,
            BuyingOperationStatus.EXPIRED,
            BuyingOperationStatus.CONFIRMED,
            BuyingOperationStatus.ONAPPEAL,
            BuyingOperationStatus.CANCELED,
        ].includes(payment.value?.status)
    ) {
        // Don't show subnavbar if agent wan`t found
        status = !(
            [BuyingOperationStatus.EXPIRED, BuyingOperationStatus.CANCELED].includes(payment.value?.status) &&
            payment.value?.account === null
        );
    }

    return status;
}
const recreatePayment = async (callback: Function) => {
    try {
        pageLoading.value = true;
        await paymentService.recreatePayment();
    } catch (e: any) {
        // f7.dialog.alert(t('g.errors.alert.default-text'), t('g.errors.alert.default-title'));
    } finally {
        callback();
        pageLoading.value = false;
    }
}
const checkAndChangePagePaddingBottom = () => {
    const pageContentEL: HTMLElement = ((self.value as any).$el as HTMLElement).querySelector('.page-content')!;
    if (payment.value &&
        payment.value?.status && [
            BuyingOperationStatus.CANCELED,
            BuyingOperationStatus.REJECTED,
            BuyingOperationStatus.CONFIRMED,
            BuyingOperationStatus.EXPIRED,
            BuyingOperationStatus.ACCEPTED,
        ].includes(payment.value?.status)
    ) {
        pageContentEL.style.cssText += "--f7-page-content-extra-padding-bottom: 76px";
    } else {
        pageContentEL.style.cssText += "--f7-page-content-extra-padding-bottom: 0px";
    }
};
const checkAndRunTimerCallbackFunc = () => {
    if (payment.value &&
        payment.value?.status && [
            BuyingOperationStatus.EMPTY,
            BuyingOperationStatus.CREATED,
            BuyingOperationStatus.CASHED,
            BuyingOperationStatus.ACCEPTED,
            BuyingOperationStatus.ONAPPEAL,
        ].includes(payment.value?.status)) {
        paymentService.timerService.setCallback(paymentService.updatePayment.bind(paymentService));
    }
}

onBeforeMount(async () => {
    if (paymentService.isExternalCreated.value) {
        pageLoading.value = true;
    }
});
onMounted(() => {
    checkAndRunTimerCallbackFunc();
    if (checkShowSubnavbar()) showSubnavbar();
    checkAndChangePagePaddingBottom();
});
onUnmounted(() => {
    paymentService.reset();
});
</script>

<style lang="scss">
.payment-view {
    --f7-page-bg-color: #FFF;

    .page-content {
        padding-top: calc(var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) + var(--f7-page-subnavbar-offset, 0px) + var(--f7-page-searchbar-offset, 0px) + var(--f7-page-content-extra-padding-top, 0px)) !important;
        --f7-page-content-extra-padding-bottom: 94px;

        //&.with-transition-subnavbar {
        //    transform: translate3d(0, 0, 0);
        //    will-change: transform;
        //    transition: 0.5s padding-top cubic-bezier(0.250, 0.460, 0.450, 0.940);
        //}

        &::-webkit-scrollbar {
            width: 0;
        }
    }
}
</style>

