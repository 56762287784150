<template>
    <SlothBlock
        :title="$t('notFoundAgent.title')"
        :subtitle="$t('notFoundAgent.text')"
        :type="SLOTH_TYPE.RELAX"
    >
    </SlothBlock>
</template>

<script setup lang="ts">
import SlothBlock from "@target/components/messages/SlothBlock.vue";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";

const emits = defineEmits(["btnEvent"]);

const reloadPage = () => {
    emits("btnEvent");
}
</script>
