import {computed, ComputedRef, Ref, ref, ToRef} from "vue";
import {autobind} from "@/decorators/autobind";
import ViewController from "@/interfaces/ViewController";
import {catchErrors} from "@/decorators/catch-errors";
// @ts-ignore
import AppController from '@target/components/App/ts/AppController';
import PaymentService, {createPaymentPayload} from "@/services/operations/payment/PaymentService";
import LogService from "@/services/log-service/LogService";
import PurchaseRepository from "@/repositories/purchase/PurchaseRepository";
import AgentsAmountRanges from "@models/errors/components/AgentsAmountRanges";
import Agent from "@models/agents/Agent";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import DocumentPurchase from "@/services/firebase/firestore/documents/DocumentPurchase";
import ErrorsService from "@/services/errors-service/ErrorsService";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ModelAccount from "@models/v2/account/ModelAccount";
import ModelAccountCurrency from "@models/v2/account/ModelAccountCurrency";
import ServiceOperations from "@/services/v2/data/service-operations/ServiceOperations";

declare const window: Window & typeof globalThis & { Pusher: any, Echo: any }

export type DefaultAmount = {
    value: number;
    sign: string
}

export default class NewPaymentController implements ViewController {
    private static instance?: NewPaymentController;
    private _isCanSelectAgent: ToRef<boolean> = ref(false);
    private _isFractionalNumbersView: ToRef<boolean> = ref(false);
    private _minAmountEnabled: ToRef<boolean> = ref(false);
    private _minAmount: ToRef<number | null> = ref(null);
    private _agent: ToRef<Agent | null> = ref(null);
    private _purchaseRepository: PurchaseRepository = new PurchaseRepository();
    private DEFAULT_AMOUNT_DOLLARS = 15;
    private MIN_DEFAULT_AMOUNT_DOLLARS = 15;
    public agentsAmountRanges: Ref<AgentsAmountRanges | null> = ref(null);
    public isAgentsRangesLoading: Ref<Boolean> = ref(false);
    private readonly _selectedPaymentSystem: Ref<any> = ref(null);
    amount: Ref<string>;
    public inputCurrency: Ref<string>;
    private readonly _currency: ComputedRef<ModelAccountCurrency>;
    btnContinueLoading: Ref<boolean>;
    private _paymentService: PaymentService;
    public afterInFunction: Function | null = null;
    private _initialized: boolean = false;
    private _account: Ref<ModelAccount | null> = ref(null);

    private constructor() {
        AppController.getInstance().setPaymentRequestData(null);
        this._paymentService = AppController.getInstance().paymentService;
        this._account.value = ServiceAccount.of().account.value;
        this._currency = computed(() => this._account.value.currency);
        //TODO not used - wrong - it's used
        this.inputCurrency = this._account.value?.currency.name;

        // this._selectedPaymentSystem = ref(this.initSelectedPaymentSystem());
        // this._selectedPaymentSystem.value.currencies[0].dSums = this.calculateStartDefaultAmounts();
        this.amount = ref(`${this.calculateStartAmountValue()} ${this._currency.value.abbr}`);
        this.btnContinueLoading = ref(false);
    }

    static getInstance() {
        if (typeof this.instance === 'undefined') {
            this.instance = new NewPaymentController();
        }
        return this.instance;
    }

    public async init() {
        if (this._initialized) return;
        this.setPurchaseFirebaseConfig();
        this._initialized = true;
    }

    private setPurchaseFirebaseConfig() {
        const doc = FirebaseService.of().firestoreService?.getDocument<DocumentPurchase>(DocumentTypes.PURCHASE);
        if (doc) {
            if (doc.isCanSelectAgent) {
                this._isCanSelectAgent.value = doc.isCanSelectAgent!;
            }

            if (doc.isFractionalNumbersView) {
                this._isFractionalNumbersView.value = doc.isFractionalNumbersView!;
            }

            if (doc.minAmountEnabled) {
                this._minAmountEnabled.value = doc.minAmountEnabled!;
            }

            if (doc.defaultAmount !== null) {
                this.DEFAULT_AMOUNT_DOLLARS = doc.defaultAmount;
            }

            if (doc.minAmount !== null) {
                this._minAmount.value = doc.minAmount;
            }
        }
    }

    get isFractionalNumbersView(): ToRef<boolean> {
        return this._isFractionalNumbersView;
    }

    get agent() {
        return this._agent;
    }

    get isCanSelectAgent(): ToRef<boolean> {
        return this._isCanSelectAgent;
    }

    @autobind
    public setAgent(agent: Agent | null) {
        this._agent.value = agent;
    }

    public reset() {
        this._agent.value = null;
        this._selectedPaymentSystem.value = null;
        this.agentsAmountRanges.value = null;
    }

    public async fetchAgentsAmountRanges() {
        try {
            this.isAgentsRangesLoading.value = true;
            const modelAgentsAmountRanges = await this._purchaseRepository.getAgentsAmountRanges();

            if (this._minAmountEnabled.value) {
                this.agentsAmountRanges.value = this.calculateMinMaxAmountRangesByFirebase(modelAgentsAmountRanges);
            } else {
                this.agentsAmountRanges.value = modelAgentsAmountRanges;
            }
            return this.agentsAmountRanges.value;
        } catch (e: any) {
            LogService.of().error("NewPaymentController@loadPaymentSystems", e.message);
            ErrorsService.of().handle(e);
        } finally {
            setTimeout(() => {
                this.isAgentsRangesLoading.value = false;
            }, 314);
        }
    }

    private calculateMinMaxAmountRangesByFirebase(agentsAmountRanges: AgentsAmountRanges) {
        const { buy } = this._account.value!.currency.rates;
        let minAmount = (this._minAmount.value ?? this.MIN_DEFAULT_AMOUNT_DOLLARS) * buy;

        if (minAmount > agentsAmountRanges.min!) {
            agentsAmountRanges.min = Math.min(minAmount, agentsAmountRanges.max!);
        }

        return agentsAmountRanges;
    }

    // private initSelectedPaymentSystem() {
    //     const defaultBank = humps(DEFAULT_PAYMENT_SYSTEM);
    //     if (!this.paymentSystems.value || this.paymentSystems.value.length === 0) return defaultBank;
    //     const bank = this.paymentSystems.value.find(el => el.sysName === "anybank");
    //     return typeof bank !== "undefined"
    //         ? bank
    //         : defaultBank;
    // }

    public calculateStartAmountValue(): number {
        const {buy} = this._account.value.currency.rates;
        const doc = FirebaseService.of().firestoreService?.getDocument<DocumentPurchase>(DocumentTypes.PURCHASE);

        if (doc && doc.defaultAmount !== null) {
            this.DEFAULT_AMOUNT_DOLLARS = doc.defaultAmount;
        }

        return Math.ceil(this.DEFAULT_AMOUNT_DOLLARS * buy);
    }

    // public async fetchPSystems() {
    //     const response = await MainApiService.getInstance().get('/api/mobile/client/psystems');
    //     this.paymentSystems.value = response.data;
    // }

    get currency() {
        return this._currency;
    }

    get selectedPaymentSystem(): Ref<any> {
        return this._selectedPaymentSystem;
    }

    //TODO not used
    public getMinAmount(): number {
        return Math.ceil(this._selectedPaymentSystem.value.minAmount);
    }

    //TODO not used
    set setAmount(s: String) {
        // @ts-ignore
        this.amount.value = s;
    }

    //TODO not used
    @autobind
    @catchErrors
    async updateNeedsData() {
        // await store.dispatch('fetchAccount', null);
        // await store.dispatch('fetchPaymentSystems', null);
        // await store.dispatch('fetchCurrencies', null);
    }

    @autobind
    @catchErrors
    async updateMyPayments() {
        await ServiceOperations.of().fetchOperations({ refresh: true });
    }

    public validateMinmax() {
        let amount = parseFloat(this.amount.value.replace(/\s/g, ''));

        if (!this.agentsAmountRanges.value) return true;

        const {min, max} = this.agentsAmountRanges.value;
        if (min == null || max == null) return true;
        if (min == 0 || max == 0) return true;

        return !(amount < min || amount > max);
    }

    @autobind
    async next() {
        let amount = parseFloat(this.amount.value.replace(/\s/g, ''));

        const payload: createPaymentPayload = {
            amount: amount,
            psystem_id: null,
        }

        if (this._isCanSelectAgent && this._agent.value) {
            payload.account_ids = [this._agent.value!.id];
        }

        let operation = null;

        if (this._paymentService.payment.value?.subtype === "lite") {
            payload.msid = this._paymentService.payment.value!.msid!;
            operation = this._purchaseRepository.patchEmptyPayment(payload)
        } else {
            operation = this._paymentService.createPayment(payload);
        }

        if (operation == null) throw new Error("Something went wrong");

        FirebaseService.of().wrongRangeMetricSuccess.checkAndSendAnalyticEvent();
        FirebaseService.of().emptyRangeMetricSuccess.checkAndSendAnalyticEvent();

        return operation;
    }

    //TODO not used
    selectPSystem(psystem: any) {
        this._selectedPaymentSystem.value = psystem;
    }

    destructor() {
        this.reset();
        NewPaymentController.instance = undefined;
    }
}
