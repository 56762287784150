// @ts-ignore
import routes from "@target/core/routes";
import {ref} from "@vue/runtime-core";
import LangService from "@/services/LangService";
import {Ref, ToRef} from "vue";
import SocketService from "@/services/SockerService";
import AbstractBuyingService from "@/services/operations/AbstractBuyingService";
import PaymentService from "@/services/operations/payment/PaymentService";
import RouterService from "@/services/RouterService";
import LightAppController from "@/controllers/app/LightAppController";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import MainApiService from "@/services/api-service/MainApiService";
import LogService from "@/services/log-service/LogService";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import DocumentOthers from "@/services/firebase/firestore/documents/DocumentOthers";
import * as Sentry from "@sentry/vue";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import ServiceDI from "@/services/v2/service-DI/ServiceDI";
import ServiceReleazio from "@/services/v2/service-releazio/ServiceReleazio";
import RepositoryReleazio from "@/repositories/v2/repository-releazio/RepositoryReleazio";
import ServiceReleazioApi from "@/services/v2/service-releazio-api/ServiceReleazioApi";
import ServicePaymentIncome from "@/services/v2/data/service-payment-income/ServicePaymentIncome";
import ServiceFetchOperation from "@/services/v2/data/service-fetch-operation/ServiceFetchOperation";
import RepositoryFetchOperation from "@/repositories/v2/repository-fetch-operation/RepositoryFetchOperation";
import WithdrawalService from "@/services/operations/withdrawal/WithdrawalService";

type PaymentRequestData = { amount: number, psystem_id: number, currency_id?: number, type?: 'purchase' | 'sale' }

export default class AppController extends LightAppController {
    private static instance: AppController | null = null;
    readonly config: object;
    private _isAuth: Ref<Boolean> = ref(false);
    private _isReady: Ref<Boolean> = ref(false);
    private _paymentRequestData: ToRef<PaymentRequestData | null>;
    public socketIsConnecting: Ref<boolean> = ref(false);
    private _lightBuyingService: AbstractBuyingService | null = null;
    private _withdrawalService: WithdrawalService;
    private _routerService: RouterService;
    private _serviceAccount: ServiceAccount;

    constructor() {
        super();
        this.config = {
            name: 'light',
            theme: 'ios',
            routes: routes,
            iosTranslucentBars: false,
        }
        this._paymentRequestData = ref(null);
        this._withdrawalService = new WithdrawalService();
        this._routerService = new RouterService();

        const locale = localStorage.getItem('lang');
        if (locale) {
            LangService.getInstance().set(locale);
        }

        this._serviceAccount = ServiceAccount.of();
    }

    get routerService(): RouterService {
        return this._routerService;
    }

    get lightBuyingService(): AbstractBuyingService | null {
        return this._lightBuyingService;
    }

    //TODO not used
    get paymentRequestData() {
        return this._paymentRequestData;
    }

    get isReady() {
        return this._isReady;
    }

    get isAuth() {
        return this._isAuth;
    }

    get paymentService(): PaymentService {
        return this._paymentService;
    }

    get withdrawalService(): WithdrawalService {
        return this._withdrawalService;
    }

    protected createServiceDI() {
        return ServiceDI.builder()
            .addPack([
                {
                    key: "releazio",
                    value: new ServiceReleazio(
                        new RepositoryReleazio(
                            new ServiceReleazioApi(),
                        ),
                        import.meta.env.VITE_VERSION
                    )
                },
                {key: "router", value: new RouterService()},
                {key: "paymentIncome", value: new ServicePaymentIncome()},
                {key: "fetchOperation", value: new ServiceFetchOperation(new RepositoryFetchOperation())}
            ])
            .build();
    }

    public beforeInitStep() {
        this._serviceDI = this.createServiceDI();
        return this;
    }

    public async init(callback?: Function) {
        try {
            let pusher = {
                host: import.meta.env.VITE_API_URL.replace('https://', ''),
                key: import.meta.env.VITE_PUSHER_KEY,
                authEndpoint: import.meta.env.VITE_API_URL + "/broadcasting/auth"
            };

            //TODO как будто бы уже лишнее из-за нового класса ServiceMainApi
            MainApiService.getInstance().updateUpdateHeadersWithXToken();

            // @ts-ignore
            if (import.meta.env.VITE_USE_FIREBASE === "true") {
                await FirebaseService.of().init(async () => {
                    const config = await this.fetchFirebaseMainConfigs();
                    if (config) {
                        if (config.pusherKey) pusher.key = config.pusherKey;
                        if (config.mainApiUrl) {
                            pusher.host = config.mainApiUrl.replace('https://', '');
                            pusher.authEndpoint = config.mainApiUrl + "/broadcasting/auth"
                        }
                    }

                    if (FirebaseService.of().firestoreService) {
                        this._paymentService.timerService.updateStatusLiveTimerValues(FirebaseService.of().firestoreService!.parser.makeMapForPurchaseOperationLiveTimers());
                    }
                });
            }

            const url = new URL(window.location.href);
            if (this.isUrlHasTokenRequiredData(url)) {
                await this.initializeWithMsid(url);
                this._isAuth.value = true;
            }

            if (this._serviceAccount.account.value) {
                LangService.getInstance().set(this._serviceAccount.account.value!.settings.localization);
                const root = document.getElementsByTagName('html')[0];
                root.classList.add(this._serviceAccount.account.value.project.slug);

                SocketService.getInstance().init(this._serviceAccount.account.value.id, pusher).connect();
            }

            LogService.of().log("AppController@init", "initialized");
        } catch (e: any) {
            Sentry.captureMessage("Auth went wrong");
            Sentry.captureException(e);
            this.resetCredentials();
            this.token.value = null;
            LogService.of().error("AppController@init", "didn't initialized");
        } finally {
            this._isReady.value = true;
        }
    }

    private async fetchFirebaseMainConfigs() {
        try {
            const doc = FirebaseService.of().firestoreService!.getDocument(DocumentTypes.OTHERS) as DocumentOthers;
            const mainApiUrl = doc.mainApiUrl;
            const pusherKey = doc.pusherKey;

            if (mainApiUrl) {
                MainApiService.getInstance().setConfigDomain(mainApiUrl);
                ServiceMainApi.of().setConfigDomain(mainApiUrl);
            }

            return {
                mainApiUrl,
                pusherKey
            }
        } catch (e) {
            Sentry.captureMessage("Firebase error");
            Sentry.captureException(e);
        }
    }

    protected async initializeWithMsid(url: URL): Promise<void> {
        const {token, refreshToken, csp} = this.retrieveCredentialsFromUrl(url);
        this.persistCredentials(token, refreshToken, csp);
        this.token = token;
        await this._serviceAccount.fetchAccount();
    }

    protected isUrlHasTokenRequiredData(url: URL) {
        return !!(
            url.searchParams.get("token") &&
            url.searchParams.get("csp")
        );
    }

    public setPaymentRequestData(payload: PaymentRequestData | null) {
        this._paymentRequestData.value = payload;
    }

    public async logout() {
        this._isAuth.value = false;
        this._serviceAccount.account = null; //TODO В будущем вынести в logout У сервиса ServiceAuth
        SocketService.getInstance().disconnect();
        this.resetCredentials();
        this.token.value = null;
    }

    //TODO not used
    private checkAuth(): boolean {
        if (localStorage.getItem('ltoken') !== null) {
            return true;
        }
        return false;
    }

    public static getInstance() {
        if (AppController.instance === null) {
            AppController.instance = new AppController()
        }
        return AppController.instance
    }

    public static of() {
        if (AppController.instance === null) {
            AppController.instance = new AppController()
        }
        return AppController.instance
    }
}
