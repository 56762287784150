import { ref, Ref } from "vue";
import { Operations } from "@/repositories/v2/repository-operations/types";
import RepositoryOperations from "@/repositories/v2/repository-operations/RepositoryOperations";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";

export default class ServiceOperations {
    private static _instance: ServiceOperations | null = null;
    private readonly _operations: Ref<Operations | null>;
    private readonly _repositoryOperations: RepositoryOperations;
    private _page: Ref<number> = ref(5);
    private _results: Ref<number> = ref(10);
    private _totalPages: Ref<number> = ref(0);

    constructor() {
        this._operations = ref(null);
        this._repositoryOperations = new RepositoryOperations();
    }

    public static of() {
        if (ServiceOperations._instance === null) {
            ServiceOperations._instance = new ServiceOperations();
        }

        return ServiceOperations._instance;
    }

    public async fetchOperations(config: { refresh: boolean }) {
        if (config.refresh) this.refreshPagination();

        const payload = this.getPagination();

        const { operations, meta } = await this._repositoryOperations.fetchOperations(payload);
        this._totalPages.value = meta.lastPage;
        this._page.value++;

        if (config.refresh) {
            this._operations.value = operations;
        } else {
            this._operations.value = [
                ...this._operations.value,
                ...operations
            ];
        }
    }

    public resetChatUnreadCountByOperationId(operationId: number): boolean {
        if (this._operations.value?.length) {
            const operation = this._operations.value.find((operation) => operation.id === operationId);

            if (operation instanceof PurchaseOperation || operation instanceof WithdrawalOperation) {
                operation.resetChatUnreadCount();

                return true;
            }
        }

        return false;
    }

    public incrementChatUnreadCountByOperationId(operationId: number): boolean {
        if (this._operations.value.length) {
            const operation = this._operations.value.find((operation) => operation.id === operationId);

            if (operation instanceof PurchaseOperation || operation instanceof WithdrawalOperation) {
                operation.incrementChatUnreadCount();

                return true;
            }
        }

        return false;
    }

    public get operations(): Ref<Operations> {
        return this._operations;
    }

    public set operations(operations: Operations) {
        this._operations.value = operations;
    }

    public get page(): Ref<number> {
        return this._page;
    }

    public get totalPages(): Ref<number> {
        return this._totalPages;
    }

    private getPagination() {
        return {
            page: this._page.value,
            results: this._results.value,
        }
    }

    private refreshPagination() {
        this._page.value = 1;
        this._results.value = 10;
    }
}
