<template>
    <f7-app v-bind="vc.config">
        <Transition name="fade" mode="out-in">
            <MainViews v-if="vc.token.value || loading" />
            <f7-view main url="/auth-error" v-else :preload-previous-page="false" :ios-swipe-back="false"></f7-view>
        </Transition>

        <div class="captcha-view" v-if="vc.yandexCaptchaVisible.value">
            <div class="captcha-view__bg"></div>
            <div
                id="captcha-container"
                class="captcha-view__smart-captcha smart-captcha"
            ></div>
        </div>

        <payment-feedback-cancel
            v-if="vc.paymentService.feedbackCancelService.modalIsVisible.value"
        />
        <payment-amount-changed-modal v-if="vc.paymentService.isAmountWasChangedModalVisible.value"/>
    </f7-app>
</template>

<script lang="ts" setup>
// @ts-ignore
import {f7, f7ready} from 'framework7-vue';
import AppController from "./ts/AppController";
import MainViews from "../views/MainViews.vue";

const vc = AppController.getInstance();
const loading = ref(true);

onMounted(async () => {
    try {
        loading.value = true;
        await vc.init(() => {
            loading.value = false
        });
        loading.value = false;
    } catch (e) {
        console.log("HERE", e)
        // loading.value = false;
    } finally {

    }
})

import {onMounted, ref} from "vue";
import PaymentFeedbackCancel from "@components/modals/payment-feedback-cancel/PaymentFeedbackCancel.vue";
import PaymentAmountChangedModal from "@components/modals/payment-amount-changed/PaymentAmountChangedModal.vue";
</script>

<style lang="scss">
.no-data-page {
    .page-content {
        .info-block {
            margin-top: 56px;
        }
    }
}
.captcha-view {
    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 99998;
        background: rgba(0, 0, 0, 0.4);
        height: 100%;
        width: 100%;
    }
    &__smart-captcha {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: 99999;
        height: 122px;
        width: 300px;

    }
    iframe {
        background-color: #FFF;
        border-radius: 6px;
    }
}
</style>
